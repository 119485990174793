import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ seo = null, ogImage = null }) => {
  const {
    site: { siteMetadata: seoDefaultSite },
    wp: { seo: seoDefaultWP, allSettings: settingsWP },
  } = useStaticQuery(graphql`
    query SEODefaultQuery {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          url
          image
        }
      }
      wp {
        allSettings {
          generalSettingsDescription
          generalSettingsTitle
        }
        seo {
          schema {
            siteName
            companyName
            companyLogo {
              sourceUrl
              title
            }
            logo {
              sourceUrl
              title
            }
            wordpressSiteName
          }
          openGraph {
            frontPage {
              description
              title
            }
            defaultImage {
              sourceUrl
            }
          }
        }
      }
    }
  `);

  const {
    title: defaultTitle,
    titleTemplate,
    description: defaultDescription,
    url: siteUrl,
    image: defaultImage,
  } = seoDefaultSite;

  const seoDefault = {
    title:
      seo?.title ||
      seoDefaultWP.schema.siteName ||
      settingsWP.generalSettingsTitle ||
      defaultTitle,
    description:
      seo?.metaDesc ||
      settingsWP.generalSettingsDescription ||
      defaultDescription,
    image:
      seo?.opengraphImage?.sourceUrl || seo?.image || ogImage || defaultImage,
    url: seo?.opengraphUrl || siteUrl,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={seoDefault.title}
      titleTemplate={titleTemplate}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: seo?.schema?.raw,
        },
        // {
        //   type: 'text/javascript',
        //   src:
        //     'https://go.screver.com/scripts/embed-survey?s=6202880753fdbf3478ce47bc&t=popover&c=capptoo&btnL=Pass the survey&i=question&sp=right&w=100%&h=800px&hH=true&hF=true',
        // },
      ]}
    >
      <meta
        name="description"
        content={seo?.opengraphDescription || seoDefault.description}
      />
      <meta name="image" content={seoDefault.image} />

      {/* OG */}
      <meta property="og:url" content={seoDefault.url} />
      {seo?.opengraphType && (
        <meta property="og:type" content={seo.opengraphType} />
      )}
      <meta
        property="og:title"
        content={seo?.opengraphTitle || seoDefault.title}
      />
      {seoDefault.description && (
        <meta
          property="og:description"
          content={seo?.opengraphDescription || seoDefault.description}
        />
      )}

      {ogImage ? (
        <meta property="og:image" content={ogImage} />
      ) : (
        <meta property="og:image" content={seoDefault.image} />
      )}
      {/* OG */}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {seo?.opengraphAuthor && (
        <meta name="twitter:creator" content={seo.opengraphAuthor} />
      )}
      <meta
        name="twitter:title"
        content={seo?.twitterTitle || seoDefault.title}
      />
      <meta
        name="twitter:description"
        content={
          seo?.twitterDescription ||
          seo?.opengraphDescription ||
          seoDefault.description
        }
      />

      <meta
        name="twitter:image"
        content={
          seo?.twitterImage?.sourceUrl
            ? seo?.twitterImage?.sourceUrl
            : seoDefault.image
        }
      />

      {/* Twitter */}

      <meta name="theme-color" content="#6f46fe" />
    </Helmet>
  );
};
export default SEO;
